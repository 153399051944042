import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    plate: sessionStorage.getItem('plate'),
    results: objectFromSession('results', []),
    selected: objectFromSession('selected'),
    paid: objectFromSession('paid', false),
    paidAmount: objectFromSession('paidAmount', 0.00)
  },
  getters: {
    plate (state) {
      return state.plate
    },
    results (state) {
      return state.results
    },
    selected (state) {
      return state.selected
    },
    paid (state) {
      return state.paid
    },
    paidAmount (state) {
      return state.paidAmount
    }
  },
  mutations: {
    addResult (state, res) {
      state.results.push(res)
      sessionStorage.setItem('results', JSON.stringify(state.results))
    },
    removeResult (state, res) {
      state.results = state.results.filter(x => x !== res)
      sessionStorage.setItem('results', JSON.stringify(state.results))
    },
    setPlate (state, plate) {
      sessionStorage.clear()
      sessionStorage.setItem('plate', plate)

      state.plate = plate
      state.results = []
      state.company = null
    },
    setSelected (state, item) {
      state.selected = item
      sessionStorage.setItem('selected', JSON.stringify(item))
    },
    setPaid (state, paid) {
      state.paid = paid
      sessionStorage.setItem('paid', paid)
    },
    setPaidAmount (state, amount) {
      state.paidAmount = amount
      sessionStorage.setItem('paidAmount', amount)
    }
  },
  actions: {
  },
  modules: {
  }
})

function objectFromSession (key, defaultValue = null) {
  const v = sessionStorage.getItem(key)
  return (v) ? JSON.parse(v) : defaultValue
}
